import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import StoreIcon from '@mui/icons-material/Store';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import SettingsIcon from '@mui/icons-material/Settings';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import { useEffect } from 'react';

const AdminSidebar = () => {

  const navstate = useSelector(state => state.backend.navstate);

  const slideNext = (e) => {
    e.target.classList.toggle("opened")
    e.target.nextSibling.classList.toggle("slide-opened")
  }


  useEffect(() => {
    let activelink = document.querySelector('.sidebar a.active');
    if (activelink) {
      let activesection = activelink.closest('.slide');
      if (activesection) {
        activesection.classList.add('slide-opened');
        activesection.previousSibling.classList.add("opened");
      }
    }
  });


  return (
    <div className={'sidebar ' + navstate}>
      <div className="inner">
        <div className="top">
          <span className="logo"></span>
        </div>
        <div className="center">
          <ul className='single'>
            <li><NavLink to='/'><DashboardIcon className='icon' /><span>Dashboard</span></NavLink></li>
          </ul>
          <p className='title opened' onClick={(e) => slideNext(e)}>Einstellungen<ExpandMoreIcon className='icon' /></p>
          <div className="slide slide-opened">
            <ul>
              <li><NavLink to="/shopuebersicht"><StoreIcon className='icon' /><span>Shopübersicht</span></NavLink></li>
              <li><NavLink to="/multishops"><ShareIcon className='icon' /><span>Multishops</span></NavLink></li>
              <li><NavLink to="/userverwaltung"><PeopleAltIcon className='icon' /><span>Userverwaltung</span></NavLink></li>
              <li><NavLink to="/dokumentvorlagen"><DocumentScannerIcon className='icon' /><span>Dokumentvorlagen</span></NavLink></li>
              <li><NavLink to="/printvorlagen"><PrintIcon className='icon' /><span>Printvorlagen</span></NavLink></li>
            </ul>
          </div>
          <p className='title' onClick={(e) => slideNext(e)}>DHL<ExpandMoreIcon className='icon' /></p>
          <div className="slide">
            <ul>
              <li><NavLink to="/dhlconfig"><SettingsIcon className='icon' /><span>Konfiguration</span></NavLink></li>
              <li><NavLink to="/dhlproduct"><LocalPostOfficeIcon className='icon' /><span>DHL-Produkte</span></NavLink></li>
            </ul>
          </div>

          <p className='title' onClick={(e) => slideNext(e)}>Verpackung & Versand<ExpandMoreIcon className='icon' /></p>
          <div className="slide">
            <ul>
              <li><NavLink to="/versandarten"><LocalShippingIcon className='icon' /><span>Versandarten</span></NavLink></li>
              <li><NavLink to="/verpackungsarten"><HowToVoteIcon className='icon' /><span>Verpackungsarten</span></NavLink></li>
            </ul>
          </div>

          <p className='title' onClick={(e) => slideNext(e)}>Mails<ExpandMoreIcon className='icon' /></p>
          <div className="slide">
            <ul>
              <li><NavLink to="/mailsmtp"><LocalPostOfficeIcon className='icon' /><span>SMTP</span></NavLink></li>
              <li><NavLink to="/mailconfig"><SettingsIcon className='icon' /><span>Konfiguration</span></NavLink></li>
            </ul>
          </div>

          <p className='title' onClick={(e) => slideNext(e)}>Statistiken<ExpandMoreIcon className='icon' /></p>
          <div className="slide">
            <ul>
              <li><NavLink to="/bestellungen"><ShoppingCartOutlinedIcon className='icon' /><span>Bestellungen</span></NavLink></li>
              <li><NavLink to="/aufladungen"><ElectricalServicesIcon className='icon' /><span>Aufladungen</span></NavLink></li>
              <li><NavLink to="/transaktionen"><TrendingUpIcon className='icon' /><span>Transaktionen</span></NavLink></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminSidebar